import React from "react"
import ReactTooltip from 'react-tooltip';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import 'bootstrap';

class Showcase extends React.Component {

  componentDidMount() {
    var acc = document.querySelectorAll(".accordion");
    var arr = Array.prototype.slice.call(acc);
    var panels = document.querySelectorAll('.panel');
    arr.forEach( function(item, index) {
      item.addEventListener("click", function() {
        var panel = panels[index];
        if (panel.style.display === "block") {
          panel.style.display = "none";
          item.innerHTML = 'Show More';
        } else {
          panel.style.display = "block";
          item.innerHTML = 'Show Less';
        }
      });
    });
  }

  render() {

    const { data, location } = this.props;
    const pageInfo = data.markdownRemark;

    return (
      <Layout  data={data} location={location}>
        <div className='intro text-center col-xs-12 col-sm-8 col-md-8' dangerouslySetInnerHTML={{ __html: pageInfo.frontmatter.introduction}}>
        </div>
        <div className='disclaimer text-center' >
          <ReactTooltip place={'bottom'} effect={'solid'} multiline={true}/>
          <p data-tip={ pageInfo.frontmatter.disclosure }>Advertising disclaimer</p>
        </div>

        { pageInfo.frontmatter.products.map( (product, index) => {
        return (
        <div className='card col-xs-12' key={index}>
          <button className="rank-number" style={{backgroundColor: product.rank_color}} >{index + 1}</button>
          <div className='row-container'>
            <div className='row-1'>
              <div className='logo col-xs-12 col-sm-4'>
                <img src={product.product_logo} style={{textAlign: 'center'}}alt='' />
              </div>
              <div className='highlights  col-xs-12 col-sm-8'>
                <h4 className='mb-3'>{product.product_name}</h4>
                {product.key_points.map( (item, index) => {
                return <p key={index}><span className='tick'>&#10004;&#xFE0E;&nbsp;&nbsp;&nbsp;</span>{item.point}<br></br></p>
                })}
              </div>
            </div>
            <div className='row-2 '>
              <div className='cta-button'>
                <a href={product.button_url}><button className='cta' style={{background: product.button_color}}>{product.cta}</button></a>
              </div>
              <div className='rating'>
                <img src={`/images/${product.star_rating}.png`} alt='' />
              </div>
            </div>
          </div>
          <div className='row-3'>
            <button className="accordion" ><strong>Show More</strong></button>
          </div>

          <div className='panel' style={{marginTop: '40px'}}>
            <div className='col-12 extra-info' style={{display: 'flex'}}>
            <div className='row'>
              <div className='col-xs-12 col-sm-9' dangerouslySetInnerHTML={{ __html: product.more_info.more_info_content }}>
                
              </div>
              <div className='col-xs-12 col-sm-3 mt-3 text-center' >
                <img className='img-fluid' src={product.more_info.product_image} alt='' />
              </div>
              </div>
            </div>
            <div className='links mt-4'>
              <a href={product.more_info.review_link}>Read review</a>
              <a href={product.more_info.site_link} style={{float: 'right'}}>Visit Site >></a>
            </div>
          </div>
        </div>
          );
        }) }
      </Layout>
    );
  }
}

export default Showcase;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        template
        title
        type
        red_track
        introduction
        disclosure
        products {
          product_name
          rank_color
          button_color
          product_logo
          cta
          key_points {
            point
          }
          star_rating
          more_info {
            product_image
            more_info_title
            more_info_content
            review_link
            site_link
          }
        }
        conclusion
      }
    }
  }
`;